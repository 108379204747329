import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable()
export class CustomerResolver implements Resolve<any> {

    constructor(
        private apiSerivce: ApiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<any> {
        let url = 'customer/total';

        return this.apiSerivce.get(url)
            .pipe(map(res => res['data']));
    }

}